.homeTitleWrapper {
    font-family: 'Inconsolata', monospace;
    font-size: xx-large;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #1a765c;
}

.homeTitle {
    display: inline-block;
}

.homeTitle1 {
    overflow: hidden;
    white-space: nowrap;
    width: 0;
    border-right: .1em solid grey;
    animation: typing 1s steps(25, end) forwards;
    animation-delay: 0s;
}

.homeTitle2 {
    overflow: hidden;
    white-space: nowrap;
    width: 0;
    border-right: .1em solid grey;
    animation: typing 1s steps(25, end) forwards;
    animation-delay: 1s;
}

.homeTitle3 {
    overflow: hidden;
    white-space: nowrap;
    width: 0px;
    border-right: .1em solid grey;
    animation: typing 1s steps(25, end) forwards;
    animation-delay: 2s;
}

.homeTitle4 {
    overflow: hidden;
    white-space: nowrap;
    width: 0px;
    border-right: .1em solid grey;
    animation: typing 1s steps(25, end) forwards;
    animation-delay: 3s;
}

.removeBorder {
    border-right: 0px !important;
}

.noMove {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
}

.finalState {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    border-right: .1em solid grey;
    animation: blink .8s infinite;
}

.homeTitlePlaceHolder {
    overflow: hidden;
    white-space: nowrap;
    border-right: .1em solid grey;
    visibility: hidden;
}

@keyframes typing {
    from {
        width: 0
    }

    to {
        width: 100%;
    }
}

@keyframes blink {
    from {
        border-color: rgba(0, 0, 0, 0)
    }

    to {
        border-color: grey;
    }
}

@media (max-width: 600px) {
    .homeTitleWrapper {
        font-size: x-large;
    }
}

@media (max-width: 450px) {
    .homeTitleWrapper {
        font-size: medium;
    }
}