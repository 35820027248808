.projectDescriptionTitle {
    font-family: 'Inconsolata', monospace;
    font-size: xx-large;
    margin-bottom: 20px;
    margin-top: 20px;
}

.projectDescriptionStack {
    width: 80%;
    margin-bottom: 20px;
}

.projectDescriptionLinks {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}

.projectDescriptionLinkItem:hover {
    text-decoration: underline;
}

.projectDescriptionContent {
    margin-bottom: 40px;
}

.projectDescriptionSubtitle {
    font-weight: 800;
    font-size: large;
    margin-bottom: 10px;
}

.projectDescriptionImage {
    display: flex;
    justify-content: center;
}

.projectDescriptionImage>img {
    max-width: 100%;
    max-height: 50vh;
    margin-bottom: 40px;
    border: 5px solid black;
}