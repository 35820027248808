.projectsOuterWrapper {
    width: 85%;
    margin: auto;
    margin-top: 10vh;
}

.projectTop {
    display: flex;
}

.projectTitle {
    position: fixed;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 900;
    font-size: 40px;
    margin-bottom: 10px;
    width: 25vw;
    margin-right: 50px;
}

.projectTitlePlaceHolder {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 900;
    font-size: 40px;
    width: 25vw;
    margin-right: 50px;
    margin-bottom: 10px;
    color: rgba(0, 0, 0, 0);
}

.projectContent {
    font-family: 'Inconsolata', monospace;
    flex: auto;
    align-self: flex-end;
    margin-bottom: 10px;
}


.projectBottom {
    position: relative;
    display: flex;
    flex-direction: col;
    flex-grow: 1;
}

.projectNav {
    background-color: rgba(0, 0, 0, 0);
    padding-top: 10px;
    border-top: 1px solid #b8b8b8;
    border-radius: 2px;
    position: fixed;
    overflow-y: scroll;
    width: 25vw;
    height: 70vh;
}

.projectNav::-webkit-scrollbar {
    display: none;
}

.projectNav {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.projectNavItem {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px 10px 10px 10px;
    border-radius: 2px;
    background-color: #e7e7e7;
    margin-bottom: 10px;
}

.projectNavItem>* {
    pointer-events: none;
}

.projectNavItem:hover {
    transition: 0.3s;
    cursor: pointer;
    background-color: black;
}

.projectNavItem:hover .projectNavTitle {
    color: white;
}

.selectedProjectNavItem {
    background-color: black;
}

.projectNavThumbNail {
    object-fit: cover;
    width: 75px;
    height: auto;
    border-radius: 3px;
    margin-right: 10px;
}

.projectNavTextContent {
    font-size: smaller;
    align-self: flex-end;
    color: grey;
}

.projectNavType {
    font-family: 'Inconsolata', monospace;
    margin-right: 10px;
}

.projectNavTitle {
    margin-top: 5px;
    font-family: 'Inconsolata', monospace;
    color: black;
    font-size: medium;
}

.selectedProjectNavTitle {
    color: white;
}

.projectNavPlaceHolder {
    width: 25vw;
    margin-right: 50px;
    padding-top: 10px;
    border-top: 1px solid rgba(0, 0, 0, 0);
}

.projectDescription {
    text-overflow: clip;
    flex: 1;
    font-family: 'Inconsolata', monospace;
    border-top: 1px solid #b8b8b8;
    margin-bottom: 100px;
}

@media (max-width: 1000px) {
    .projectBottom {
        flex-direction: column;
    }

    .projectTitle {
        display: none;
    }

    .projectTitlePlaceHolder {
        display: none;
    }

    .projectNav {
        border: none;
        display: flex;
        flex-direction: row;
        width: 100%;
        height: min-content;
        left: 0px;
        bottom: 0px;
        background-color: aliceblue;
    }

    .projectNavItem {
        margin-right: 10px;
        padding-right: 20vw;
    }

    .projectNavPlaceHolder {
        display: none;
    }
}