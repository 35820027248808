.name {
    font-family: 'Inconsolata', monospace;
    font-weight: 900;
    font-size: x-large;
}

.topbar {
    z-index: 10;
    position: fixed;
    display: flex;
    width: calc(100vw - 32px - 32px);
    justify-content: space-between;
    padding: 32px 32px 0px 32px;
}

.topbarPlaceHolder {
    display: flex;
    justify-content: space-between;
    padding: 32px 32px 0px 32px;
    visibility: hidden;
}

.menu {
    display: flex;
    align-items: center;
}

.menuImage {
    max-width: 22px;
    height: auto;
}

.menuItemImage {
    display: flex;
    justify-content: center;
    align-items: center;
}

.menuItem {
    position: relative;
    text-align: center;
    margin: 0px 10px 0px 10px;
}

.namedItem {
    height: min-content;
    font-family: 'Inconsolata', monospace;
    text-align: center;
    color: black;
    background-color: rgba(231, 231, 231, 0.4);
    padding: 4px 15px 5px 15px;
    border-radius: 3px;
    font-size: small;
}

.namedItem:hover {
    background-color: rgba(231, 231, 231, 0.7);
}

.selectedItem {
    background-color: rgba(231, 231, 231, 0.8) !important;
    text-decoration: underline;
}