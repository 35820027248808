.aboutOuterWrapper {
    width: 85%;
    margin: auto;
    margin-top: 20vh;
}

.lottieImage {
    position: absolute;
    width: 25vw;
    height: 25vw;
    top: 50%;
    right: 10%;
    transform: translate(10%, -50%);
    background-image: url("../public/face.png");
    background-size: cover;
}

.contentBlock {
    position: relative;
    margin-bottom: 25vh;
}

.title {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 900;
    font-size: 40px;
    margin-bottom: 20px;
}

.titleSlash {
    color: grey;
}

.content {
    font-family: 'Inconsolata', monospace;
    font-size: large;
    line-height: 30px;
    width: 45%;
    margin-bottom: 20px;
}

.educationBlock {
    display: flex;
    align-items: center;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 10px;
    width: 48%;
    background-color: #E7E7E7;
}

.educationImage {
    width: 100px;
    height: auto;
    display: inline;
}

.year {
    font-family: 'Inconsolata', monospace;
    font-size: x-large;
    font-weight: 900;
}

.educationDesciption {
    font-family: 'Inconsolata', monospace;
    font-size: medium;
}

.bullet {
    font-family: 'Inconsolata', monospace;
    list-style-type: circle;
    font-size: medium;
    margin: 0;
    padding-left: 20px;
    margin-bottom: 25px;
}

@media (max-width: 1400px) {
    .content {
        width: 75%;
    }

    .educationBlock {
        width: 80%;
    }

    .lottieImage {
        background-image: none;
        visibility: hidden;
    }
}

@media (max-width: 800px) {
    .title {
        font-size: 35px;
    }

    .content {
        width: 90%;
    }
}

@media (max-width: 700px) {
    .aboutOuterWrapper {
        margin-top: 15vh;
    }

    .title {
        font-size: 30px;
    }

    .content {
        width: 95%;
    }
}

@media (max-width: 600px) {
    .aboutOuterWrapper {
        margin-top: 10vh;
    }

    .title {
        font-size: 23px;
    }

    .content {
        width: 100%;
    }
}